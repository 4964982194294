// import React from 'react'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// const lifeSlider = () => {

//     const images = [
//         {
//           id: 1,
//           videoId: 293940724,
//           url: "https://vimeo.com/538105209",
//           thumbnail: "/images/1.jpeg",
//           // hunk
//           src: "1.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "New Release",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 16+",
//           description:
//             "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
//           genres: ["Drama", "Thriller"],
//         },
//         {
//           id: 2,
//           videoId: 291681025,
//           url: "https://vimeo.com/291681025",
//           // vadilal
//           thumbnail: "/images/2.jpeg",
//           src: "2.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "The Witcher: Blood Origin",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 16+",
//           description:
//             "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
//           genres: ["Drama", "Action"],
//         },
//         {
//           id: 3,
//           videoId: 333499420,
//           url: "https://vimeo.com/333499420",
//           thumbnail: "/images/3.jpeg",
//           src: "3.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "Spider-Man: A New Beginning",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 12+",
//           description:
//             "Peter Parker's journey continues as he takes on new villains and navigates the challenges of both his superhero and personal life.",
//           genres: ["Action", "Adventure"],
//         },
//         {
//           id: 4,
//           videoId: 290693752,
//           url: "https://vimeo.com/290693752",
//           thumbnail: "/images/4.jpeg",
//           // hyundai
//           src: "4.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "Avatar: The Way of Water",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 16+",
//           description:
//             "Jake Sully and Neytiri fight to protect their family from new threats in the lush and vibrant world of Pandora.",
//           genres: ["Thriller", "Adventure"],
//         },
//         {
//           id: 5,
//           videoId: 330036411,
//           url: "https://vimeo.com/278793691",
//           // godrej
//           thumbnail: "/images/5.jpeg",
//           src: "5.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "Karmma Calling",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 16+",
//           description:
//             "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
//           genres: ["Thriller", "Adventure"],
//         },
//         {
//           id: 6,
//           videoId: 276703567,
//           url: "https://vimeo.com/276703567",
//           // vadilal friends
//           thumbnail: "/images/6.jpeg",
//           src: "6.jpeg",
//           title: "images/753845722_karmma_calling.png",
//           content: "The Batman: Dark Knight Rises",
//           year: "2024",
//           season: "1 Season",
//           language: "7 Languages",
//           rating: "U/A 16+",
//           description:
//             "Batman faces his greatest challenge yet as he takes on a new adversary threatening Gotham City.",
//           genres: ["Thriller", "Adventure"],
//         },
//         // Add more objects as needed
//       ];

//       const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         centerMode: true, // Center the active slide
//         autoplay: false,
//         autoplaySpeed: 4000,
    
//         // Enable autoplay
//         // Set the autoplay speed to 2 seconds (2000ms)
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 4,
//               slidesToScroll: 1,
//               infinite: true,
//             },
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//             },
//           },
//         ],
//         beforeChange: (current, next) => setActiveIndex(next), // Update active index on slide change
//       };

//   return (
//     <div>
//        <Slider {...settings}>
//                   {images.map((img, index) => (
//                     <div
//                       key={index}
//                       className={`relative md:w-[5.94vw] md:h-[14.41vh]  hover:scale-[1.02] ease-in transition-transform duration-300 !cursor-pointer ${
//                         activeIndex === index
//                           ? "scale-110 border-2 border-green-500"
//                           : "scale-90"
//                       }`}
//                     >
//                       <img
//                         src={`/images/${img.src}`}
//                         alt={`image-${img.id}`}
//                         className="w-full h-full object-cover"
//                       />
//                     </div>
//                   ))}
//                 </Slider>
//     </div>
//   )
// }

// export default lifeSlider
import React, { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import custom icons for prev and next
import right from '../../Assets/img/rightarrow.png';
import left from '../../Assets/img/Leftarrow.png';
import { Container } from "react-bootstrap";

const LifeSlider = ({ref3}) => {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active index

  const videos = [
    {
      id: 1,
      videoId: 'mdacs_CkXvw', // YouTube video ID
      url: "https://www.youtube.com/embed/mdacs_CkXvw?si=xJ_KwrLbn2tyBUE7",
      title: "New Release",
      content: "New Release",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Nothing matters when the world is at your feet. But can anyone really cheat one's Karma?",
      genres: ["Drama", "Thriller"],
    },
    {
      id: 2,
      videoId: 'mdacs_CkXvw', // YouTube video ID
      url: "https://www.youtube.com/embed/mdacs_CkXvw?si=xJ_KwrLbn2tyBUE7",
      title: "The Witcher: Blood Origin",
      content: "The Witcher: Blood Origin",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 16+",
      description:
        "Explore the origins of the Witcher universe in this epic tale of magic and monsters.",
      genres: ["Drama", "Action"],
    },
    {
      id: 3,
      videoId: 'mdacs_CkXvw', // YouTube video ID
      url: "https://www.youtube.com/embed/mdacs_CkXvw?si=xJ_KwrLbn2tyBUE7",
      title: "Spider-Man: A New Beginning",
      content: "Spider-Man: A New Beginning",
      year: "2024",
      season: "1 Season",
      language: "7 Languages",
      rating: "U/A 12+",
      description:
        "Peter Parker's journey continues as he takes on new villains and navigates the challenges of both his superhero and personal life.",
      genres: ["Action", "Adventure"],
    },
    // Add more video objects as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Enable center mode
    centerPadding: '20%', // Show part of the left and right slides
    autoplay: false,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => setActiveIndex(next), // Update active index before slide changes

    nextArrow: <CustomNextArrow />, // Custom Next Arrow
    prevArrow: <CustomPrevArrow />, // Custom Prev Arrow

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
          centerPadding: '10%', // Adjust padding for medium screens
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on small screens
        },
      },
    ],
  };

  return (
    <div className='bg-orange-50 pb-[11.11vh]'  ref={ref3}>
         <div className="UGMKheading ">
         <div className='relative'>
         <h3 className='md:text-[16.67vh] text-[8vh] text-[#D5B376] font-extrabold absolute md:top-[-65px]  top-[-40px] opacity-10 '>Student Life</h3>
         </div>
          <Container className="custom-container">
            <h2 className="heading !text-[10.67vw] md:!text-[6.25vw]">Life At UGMK</h2>
          </Container>
        </div>
      <Slider {...settings}>
        {videos.map((video, index) => (
          <div
            key={index}
            className={`relative w-full p-[1.63vw] h-[300px] md:w-[85.02vw] md:h-[70.44vh] transition-transform duration-500 ease-in-out  ${
              activeIndex === index
                ? 'scale-105 z-10' // Enlarge and emphasize the active slide
                : 'scale-90 opacity-70'
            }`}
          >
            <iframe
              width="100%"
              height="100%"
              src={video.url} // Embed the YouTube video
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full object-cover "
            ></iframe>
          </div>
        ))}
      </Slider>
    </div>
  );
};

// Custom Prev Arrow component
const CustomPrevArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="absolute md:left-[13vw] left-[4vw] top-1/2 transform -translate-y-1/2 z-10  text-white rounded-full"
    >
      <img src={left} alt="" className='h-[15vw] w-[15vw] md:h-auto md:w-auto' />
    </div>
  );
};

// Custom Next Arrow component
const CustomNextArrow = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="absolute md:right-[13vw] right-[4vw] top-1/2 transform -translate-y-1/2 z-10  text-white rounded-full"
    >
      <img src={right} alt="" className='h-[15vw] w-[15vw] md:h-auto md:w-auto'/>
    </div>
  );
};

export default LifeSlider;
