import React, { useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { Container, Nav } from "react-bootstrap";
import { icons } from "../Assets/imageConstants";
import { Link } from "react-router-dom";
import "./footer.scss";

const address = `205,Kalpana Apartment, New Kantwadi Road, Off Perry Cross Road Bandra West, Mumbai-400050, India`;
const contact = `+91 9820410648`;

const Footer = () => {
  const [showQuickLinks, setShowQuickLinks] = useState(true);
  const [showLegalities, setShowLegalities] = useState(false);

  const toggleQuickLinks = () => setShowQuickLinks(!showQuickLinks);
  const toggleLegalities = () => setShowLegalities(!showLegalities);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling
    });
  };

  return (
    <section className="footerSection">
      <Container className="custom-container">
        <div className="gulamFooterwrapper">
          <div className="socialMedia">
            <div className="FooterLogo">
              <Link to="/">
                <img src={icons.Logo} alt="" title="" />
              </Link>
            </div>
            <ul>
              <li>
                <Nav.Link href="#link">
                  <img src={icons.Spotify} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://twitter.com/" target="_blank">
                  <img src={icons.Socialicon} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/" target="_blank">
                  <img src={icons.Instagram} alt="" title="" />
                </Nav.Link>
                <Nav.Link href="https://www.facebook.com/your_page_id" target="_blank">
                  <img src={icons.Facebook} alt="" title="" />
                </Nav.Link>
              </li>
            </ul>
          </div>

          <div className="FooterLinks responsiveLinks">
            <div className="flex justify-between w-[40vw] md:w-auto">
              <h4>QUICK LINKS</h4>
              <span className="block md:hidden font-medium text-[#8C1211]" onClick={toggleQuickLinks}>
                {showQuickLinks ? <BiMinus /> : <BiPlus />}
              </span>
            </div>
            <ul className="hidden md:block ">
              <li><Link to="/about-us">About</Link></li>
              <li><Link to="#">Courses</Link></li>
              <li><Link to="#">News & Events</Link></li>
            </ul>
            <ul className={`md:hidden ${showQuickLinks ? 'block' : 'hidden'}`}>
              <li ><Link to="/about-us"><div className="!text-[4vw]">About</div></Link></li>
              <li ><Link to="#"><div className="!text-[4vw]">Courses</div></Link></li>
              <li ><Link to="#"><div className="!text-[4vw]">News & Events</div></Link></li>
            </ul>
          </div>

          {/* LEGALITIES Section with Toggle */}
          <div className="FooterLinks responsiveLinks">
            <div className="flex justify-between w-[40vw] md:w-auto">
              <h4>LEGALITIES</h4>
              <span className="block md:hidden font-medium text-[#8C1211]" onClick={toggleLegalities}>
                {showLegalities ? <BiMinus /> : <BiPlus />}
              </span>
            </div>
            <ul className="hidden md:block">
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/terms-of-use">Terms Of Use</Link></li>
              <li><Link to="/refund-policy">Refund Policy</Link></li>
              <li><Link to="/faqs">FAQs</Link></li>
            </ul>
            <ul className={`md:hidden ${showLegalities ? 'block' : 'hidden'}`}>
              <li><Link to="/privacy-policy"><div className="!text-[4vw]">Privacy Policy</div></Link></li>
              <li><Link to="/terms-of-use"><div className="!text-[4vw]">Terms Of Use</div></Link></li>
              <li><Link to="/refund-policy"><div className="!text-[4vw]">Refund Policy</div></Link></li>
              <li><Link to="/faqs"><div className="!text-[4vw]">FAQs</div></Link></li>
            </ul>
          </div>

          <div className="FooterLinks">
            <h4 className="">CONTACT</h4>
            <div className="addressBox ">
              <div className="icon md:pt-2">
                <img src={icons.Location} alt="" title="" className="!w-[4vw] md:!w-[2vw]" />
              </div>
              <div className="location">
                <Nav.Link
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`}
                  target="_blank"
                >
                  <div className="text-[3.5vw] md:text-[1vw]">{address}</div>
                  
                </Nav.Link>
              </div>
            </div>
            <div className="addressBox">
              <div className="icon md:pt-2">
                <img src={icons.Email} alt="" title="" className="!w-[3.5vw] md:!w-[1vw]" />
              </div>
              <div className="location">
                <Nav.Link href="mailto:ustadghulammustafakhan@gmail.com" target="_blank">
                  <div className="text-[3.5vw] md:text-[1vw]">ustadghulammustafakhan@gmail.com</div>
                </Nav.Link>
              </div>
            </div>
            <div className="addressBox">
              <div className="icon md:pt-1">
                <img src={icons.Call} alt="" title="" className="!w-[3.5vw] md:!w-[1vw]"/>
              </div>
              <div className="location conumber">
                <Nav.Link href={`tel:${contact}`}><div className="text-[3.5vw] md:text-[1vw]">+91 9820410648/</div></Nav.Link>
                <div className="secondCoNumber">
                  <Nav.Link href={`tel:${contact}`}><div className="text-[3.5vw] md:text-[1vw]">+91 9833786786</div></Nav.Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerBottom py-[5vh] md:py-[0vh]">
          <div className="innerBox !text-start">
            Ustad Ghulam Mustafa Khan &copy; All Rights Reserved - 2024
          </div>
          <div className="innerBox2 !text-start py-[5vh] md:py-[0vh] cursor-pointer" onClick={scrollToTop}>
           <a href="https://www.togglehead.in/"><div className=" no-underline text-[#1E1E1E] hover:text-red-600">Crafted by togglehead</div></a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Footer;
