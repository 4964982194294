import React from "react";
import "./ugmkprodigies.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";


const UgmkProdigies = () => {
  return (
    <>
    <section className="ContactBanner">
    <div className="BannerImg">
          <img src={icons.Contactbanner} alt="" title="" />
          <div className="innerbannerContent">
            <Container className="custom-container">
              <div className="breadcrumb-wrapper !mt-[-85px]">
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>/</li>
                  <li>
                  UGMK Prodigies
                  </li>
                </ul>
              </div>
              <h2>UGMK Prodigies</h2>
            </Container>
          </div>
        </div>
      </section>
            
    </>
  )
}

export default UgmkProdigies