import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import "./frequentlyAskedQue.scss";


const faqs = [
  {
    question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
  },
  {
    question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
  },
  {
    question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
  },
  {
    question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
    answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown",
  },
];

// const FAQItem = ({ question, answer, isOpen, onClick }) => (

//   <>
//     <div className="panel panel-default">
//       <div className="panel-heading" role="tab" id="headingOne" onClick={onClick}>
//         <h4 className="panel-title">
//           <a
//             className="collapsed"
//             data-toggle="collapse"
//             data-parent="#accordion"
//             href="#collapseOne"
//             aria-expanded={isOpen}
//             aria-controls={isOpen ? "collapseTwo" : "collapseOne"}
//           >
//             {question}
//           </a>
//         </h4>
//       </div>
//       <div
//         id="collapseOne"
//         className="panel-collapse collapse"
//         role="tabpanel"
//         aria-labelledby="headingOne"
//       >
//         {isOpen && (
//           <div className="panel-body">
//             {answer}
//           </div>

//         )}
//       </div>
//     </div>

//   </>
// );

const FrequentlyAskedQue = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
      <section className="ContactBanner">
        <div className="BannerImg mob-faq">
          <img src={icons.FAQ} alt="" title="" />
          <div className="innerbannerContent">
            <Container className="custom-container">
              <div className="breadcrumb-wrapper">
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>/</li>
                  <li>
                  FAQs
                  </li>
                </ul>
              </div>
            
              <h2 className="dekstop-content">
                Frequently Asked Questions
              </h2>
              <h2 className="mob-content">FAQs</h2>
            </Container>
          </div>
        </div>
      </section>
      <section className="FAQcontent">
        <Container className="custom-container">
          <div
            className="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
          </div>
          {faqs.map((faq, index) => (
            <div className="panel panel-default">
              <div className="panel-heading" role="tab" id="headingOne">
                <h4 className="panel-title">
                  <Link
                    className="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href={"#"}
                    aria-expanded={activeIndex === index?true:false}
                    aria-controls={activeIndex === index ? "collapseTwo" : "collapseOne"}
                    onClick={() => toggleFAQ(index)}
                    style={{
                      cursor: 'pointer',
                      textDecoration:'none',
                      color:activeIndex === index?'#8C1211':'#1E1E1E'
                    }}
                  >
                    {faq.question}
                  </Link>
                </h4>
              </div>
              <div
                id="collapseOne"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                {activeIndex === index && (
                  <div className="panel-body">
                    {faq.answer}
                  </div>
                )}
              </div>
            </div>
          ))}
        </Container>
      </section>
    </div>
  );
};

export default FrequentlyAskedQue;

///////mahesh


// import React, { useState } from "react";
// import { Container } from "react-bootstrap";
// import "./frequentlyAskedQue.scss";

// const FAQItem = ({ question, answer, isOpen, onClick }) => (
//   <div className="faq-item">
//     <div className="faq-header" onClick={onClick}>
//       <h4 className={`faq-question ${isOpen ? "open" : ""}`}>{question}</h4>
//       <span className={`faq-toggle ${isOpen ? "minus" : "plus"}`}>{isOpen ? "-" : "+"}</span>
//     </div>
//     {isOpen && (
//       <div className="faq-body">
//         <p>{answer}</p>
//       </div>
//     )}
//   </div>
// );

// const FrequentlyAskedQue = () => {
//   const [openIndex, setOpenIndex] = useState(null);

//   const faqs = [
//     {
//       question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
//       answer: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.",
//     },
//     {
//       question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
//       answer: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.",
//     },
//     {
//       question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
//       answer: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.",
//     },
//     {
//       question: "Lorem Ipsum is simply dummy text of the printing and typesetting?",
//       answer: "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.",
//     },
//   ];

//   const handleToggle = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div>
//       <section className="ContactBanner">
//         {/* Your existing banner code */}
//       </section>
//       <section className="FAQcontent">
//         <Container>
//           {faqs.map((faq, index) => (
//             <FAQItem
//               key={index}
//               question={faq.question}
//               answer={faq.answer}
//               isOpen={openIndex === index}
//               onClick={() => handleToggle(index)}
//             />
//           ))}
//         </Container>
//       </section>
//     </div>
//   );
// };

