import React, { useEffect, lazy, Suspense } from "react";
import "./home.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import { ugmk_prodigies } from "../../lib/homeData";
import AOS from 'aos';
import 'aos/dist/aos.css';


//Lazy load coding
const LazyUgmkAcademy = lazy(() => import('./section/ugmkAcademy'))
const LazyUgmkAbout = lazy(() => import('./section/ugmkAbout'))
const LazyMusicSpiritual = lazy(() => import('./section/spiritual'))
const LazyUgmkProdigies = lazy(() => import('./section/ugmkprodigies'))
const LazyOurProcess = lazy(() => import('./section/process'))
const LazyStudentPrograms = lazy(() => import('./section/studentPrograms'))
const LazyTestimonial = lazy(()=>import('./testimonial'))

const Home = () => {
  console.log(ugmk_prodigies);
  useEffect(() => {
    AOS.init({
      
      duration: 1000, // values from 0 to 3000, with step 50ms
     
    });
  }, []);

  return (
    <>
   

      {/* lazy Loading */}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyUgmkAcademy />
        <LazyUgmkAbout />
        <LazyMusicSpiritual />
        <LazyUgmkProdigies />
        <LazyOurProcess />
        <LazyStudentPrograms />
        <LazyTestimonial />
      </Suspense>
   
    </>
  );
};

export default Home