import "./App.css";
import { AppRoutes } from "./Routes/AppRoutes";
import Header  from "./Components/Header";
import Footer  from "./Components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToTop/>
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;


