import React from "react";
import "./academy.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";


const Academy = () => {
    return (
        <>
            <section className="ContactBanner">
                <div className="BannerImg">
                    <img src={icons.Contactbanner} alt="" title="" />
                    <div className="innerbannerContent">
                        <Container className="custom-container">
                            <div className="breadcrumb-wrapper">
                                <ul>
                                    <li>
                                        <Link to='/'>Home</Link>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        Academy
                                    </li>
                                </ul>
                            </div>
                            <h2>Academy</h2>
                        </Container>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Academy