import React from "react";
import "./success.scss";
import { Container } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";

const SuccessStory = () => {
    return (
        <>
            <section className="ContactBanner">
                <div className="BannerImg">
                    <img src={icons.Contactbanner} alt="" title="" />
                    <div className="innerbannerContent">
                        <Container className="custom-container">
                            <div className="breadcrumb-wrapper !mt-[-85px]">
                                <ul>
                                    <li>
                                        <Link to='/'>Home</Link>
                                    </li>
                                    <li>/</li>
                                    <li>
                                        Success Story
                                    </li>
                                </ul>
                            </div>
                            <h2>Success Story</h2>
                        </Container>
                    </div>
                </div>
            </section>

        </>
    )
}

export default SuccessStory;
