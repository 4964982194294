import React, { useContext, useEffect, useRef } from "react";
import "./about.scss";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LifeUGMK } from "./lifeugmk";
import { ContextApi } from "../../App";
import LifeSlider from "./Slider";
import img1 from '../../Assets/img/Murtuja.png';
import img2 from '../../Assets/img/Hasan.png';
import img3 from '../../Assets/img/qadir.png';
import OurGurusSub from "./OurGurusSub";
import OurGurusSub2 from "./OurGurusSub2";
import ReverseOurGurus from "./ReverseOurGurus";
const settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "5px",
  slidesToShow: 3.5,
  speed: 1000,
  autoplay: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        nav: false,
        speed: 1000,
        centerMode: true,
        // autoplay: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        nav: false,
        speed: 1000,
        centerMode: true,
        // autoplay: true,
      },
    },
  ],
};

const AboutPage = () => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const location = useLocation()
  useEffect(() => {
    if (location?.state?.ref === 'I') {
      ref1.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'O') {
      ref2.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'L') {
      ref3.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'A') {
      ref4.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [])
  useEffect(() => {
    if (location?.state?.ref === 'I' && ref1.current) {
      ref1.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'O' && ref2.current) {
      ref2.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'L' && ref3.current) {
      ref3.current.scrollIntoView({ behavior: "smooth" });
    } else if (location?.state?.ref === 'A' && ref4.current) {
      ref4.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
   
  const dataa = [
    {
      img : {img1},
      heading : "GHULAM MURTUZA KHAN",
      subHeading : "(Indian musical composer and record producer)",
      p1 : "Hailing from the prestigious Rampur-Sahaswan Gharana, Ghulam Murtuza Khan started training in music when he was 5. He learnt under the able guidance of his grandfather, late Ustad Waris Hussain Khan and his father, Padmabhushan Ustad Ghulam Mustafa Khan. Besides touring the world for his Sufi and fusion shows, Ghulam Murtuza Khan is also a faculty at KM MUSIC in Chennai, a music academy by Oscar-winning composer AR Rahman.  ",
      p2 : " As far as his live performances go, Ghulam Murtuza Khan has performed classical, Sufi and fusion music with Czech musicians in different cities of Czech Republic. He has also conducted music workshops across Europe and Gulf for his disciples from different countries. Some of his students from India include singer Mohammed Irfan, actress Tabu, singer Priyani Wani Pandit and composer AR Rahman's family.",
      p3 : "Besides doing playback for Bollywood, which includes songs like Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), he also collaborated with AR Rahman on the international project Warriors of Heaven and Earth and the play Bombay Dreams produced by Broadways Productions (London).",

    },
   
    {
      img : {img2},
      heading : "HASAN MUSTAFA",
      subHeading : "(Indian musical composer and record producer)",
      p1 : "The UGMK Academy is run by Ustad Ghulam Mustafa Khan’s family, and hence is an infallible centre for all things music. Our gurus - Ustad Khan’s sons Murtuza Mustafa, Qadir Mustafa and Hasan Mustafa - have nurtured several musicians, who are among the most revered names in the world of music today. The UGMK Academy is run by Ustad Ghulam Mustafa Khan’s family, and hence is an infallible centre for all things music. Our gurus ",
      p2 : "",
      p3:""

    },
    {
      img : {img3},
      heading : "GHULAM QADIR KHAN",
      subHeading : "(Indian musical composer and record producer)",
      p1 : "Hailing from the coveted Rampur-Sahaswan Gharana, Ghulam Qadir Khan started his training in music at the age of 5. Having initially learnt from his grandfather, late Ustad Waris Hussain Khan, he later received the able guidance of his father, Padmabhushan Ustad Ghulam Mustafa Khan. The musician has toured across the world for Sufi and fusion music, besides solo classical music concerts. An ace in Hindustani Classical, Geet, Ghazals and Bhajans, Ghulam Qadir Khan has also worked on several Bollywood projects with composers AR Rahman, Shankar-Ehsaan-Loy and Amit Trivedi, among others. ",
      p2 : "Some of his playback outings include Piya Haji Ali (Fiza), Chupke Se (Saathiya), Tere Bina (Guru), Haa Rehem (Aamir), Maula Mere (Anwar), Al-Madath-Maula (Mangal Pandey) and Noor-Un-Ala-Noor (Meenaxi), among others. Besides being a guru to several leading musicians and actors, Ghulam Qadir Khan has also been a vocal coach on many music reality shows, including Indian Idol, Jo Jeeta Wohi Super Star and India’s Got Talent. Some of his students include singer Armaan Malik, choreographer Terence Lewis, actress Elli Avram, singer Abhijeet Sawant (he was the first Indian Idol), Rahul Vaidya and Prajakta Shukre, among others.",
      p3:""

    }
  ]



  return (
    <>
      <section className="ContactBanner GulamAboutwrappper ">
        <div className="BannerImg mob-about">
          <img src={icons.About} alt="" title="" />
          <div className="innerbannerContent">
            <Container className="custom-container">
           
            <div className="breadcrumb-wrapper !mt-[-85px]">
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>/</li>
                  <li>
                  About Us
                  </li>
                </ul>
              </div>
              <h2>About Us</h2>
            </Container>
          </div>
        </div>
      </section>
      <section className="GulamIntroductionBanner">
        <div className="title" ref={ref1}>
          <h3>UGMK Academy</h3>
          <h2 className="heading mobhead1">Introduction</h2>
        </div>
        <div className="GulamAboutInner">
          <div className="IntroductionText">
            <p>
              With Padma Vibhushan Ustad Ghulam Mustafa Khan’s dedication
              and love towards music as its core philosophy, the Ustad
              Ghulam Mustafa Khan Academy (UGMK) offers impetus to anyone
              who dreams to pursue music with utmost assiduity and  persistence.
            </p>
            <p>
              Hailing from a family of revered musicians, with
              his maternal grandfather being Ustad Inayat Hussain Khan,
              music was the legacy of his family that was passed on from one
              generation to another.
            </p>
            <p>
              From online sessions to offline classes, from extensive and
              in-depth courses, to short-term skill-honing programmes — we
              have something for anyone who breathes and worships music like
              we do.
            </p>
          </div>
          <div className="introImg">
            <img src={icons.Introimg} alt="" title="" />
          </div>
        </div>

      </section>

      <section className="spritualArea">
        <Container className="custom-container">
          <h2 className="heading">Our Belief</h2>
          <p>
            "Music education must reach anyone who desires it wholeheartedly,
            irrespective of resources or medium"
          </p>
        </Container>
      </section>
      <div className="guruTeamWrapper pt-[8vh] md:pt-[29.44vh] bg-orange-50" ref={ref2}>
      <div className="relative">
      <h3 className="md:!text-[9.38vw] text-[16vw] md:top-[-8vh] top-[-15px]  font-extrabold absolute text-[#D5B376] opacity-10">Mentors</h3>
      </div>
              <h2 className="text-[#8C1211] heading mobhead1 font-extrabold pl-[7.35vw] text-[10.67vw] md:text-[6.25vw]">Our Gurus</h2>
              <p className="md:w-[76.60vw] w-full text-[4.27vw] md:text-[1.5vw]   font-karla font-medium  pl-[7.35vw] text-[#1E1E1E]">
                The UGMK Academy is run by Ustad Ghulam Mustafa Khan’s family, and
                hence is an infallible centre for all things music. Our gurus -
                Ustad Khan’s sons Murtuza Mustafa, Qadir Mustafa and Hasan Mustafa -
                have nurtured several musicians, who are among the most revered
                names in the world of music today.
              </p>
       </div>
        <OurGurusSub image={img1} heading={dataa[0].heading} subHeading={dataa[0].subHeading} p1={dataa[0].p1} p2={dataa[0].p2} p3={dataa[0].p3}/>
        <ReverseOurGurus image={img2} heading={dataa[1].heading} subHeading={dataa[1].subHeading} p1={dataa[1].p1} p2={dataa[1].p2} p3={dataa[1].p3}/>
        <OurGurusSub2 image={img3} heading={dataa[2].heading} subHeading={dataa[2].subHeading} p1={dataa[2].p1} p2={dataa[2].p2} p3={dataa[2].p3}/>
      
    
      <div className="py-[6vh] bg-orange-50">

      </div>
      {/* <LifeUGMK ref3={ref3} /> */}
      
      <LifeSlider ref3={ref3}/>
      <section className="awardArea" ref={ref4}>
        <h3>Achievements</h3>
        <Container className="custom-container">
          <h2 className="heading">Awards</h2>
        </Container>
        <div className="AwardSliderArea">
          <Slider {...settings}>
            <div className="AwardSlide">
              <img src={icons.Award} alt="" title="" />
              <h4>Padma Vibhushan (2018)</h4>
            </div>
            <div className="AwardSlide AwardpTop">
              <img src={icons.Award} alt="" title="" />
              <h4>Padma Bhushan (2006)</h4>
            </div>
            <div className="AwardSlide">
              <img src={icons.Award} alt="" title="" />
              <h4>Padma Bhushan (2006)</h4>
            </div>
            <div className="AwardSlide AwardpTop">
              <img src={icons.Award} alt="" title="" />
              <h4>Padma Shri (1991)</h4>
            </div>
            <div className="AwardSlide">
              <img src={icons.Award} alt="" title="" />
              <h4>Sangeet Natak Akademi (2003)</h4>
            </div>
            <div className="AwardSlide AwardpTop">
              <img src={icons.Award} alt="" title="" />
              <h4>Padma Shri (1991)</h4>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
