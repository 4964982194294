import { BsArrowRight } from "react-icons/bs"; 
import { NavDropdown } from "react-bootstrap";
import "./header.scss";
import "./custom.css";
import { icons } from "../Assets/imageConstants";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Xicon from '.././Assets/img/sidebarXicon.svg';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [image, setImage] = useState(icons.Aboutmenu);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992); // Check if screen is desktop size

  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolled(offset > 800);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992); // Update based on window width
    };
    
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImage = (val) => {
    setImage(icons.Aboutmenu);
  };

  const navigate = useNavigate();

  // Close the sidebar after clicking a link
  const closeSidebar = () => {
    const offcanvasElement = document.getElementById("navbarOffcanvas");
    if (offcanvasElement) {
      offcanvasElement.classList.remove('show');  // Just remove the 'show' class to close
    }
  };

  const scrollToSection = (ref) => {
    closeSidebar(); // Close sidebar when navigating
    navigate("/about-us", { state: { ref } });
    
    // Delay scrolling to ensure the page is loaded before scroll action
    setTimeout(() => {
      const section = document.getElementById(ref);
      if (section) {
        window.scrollTo({
          top: section.offsetTop, // Scroll to the section based on its position
          behavior: "smooth",     // Add smooth scrolling effect
        });
      }
    }, 500); // Adjust this timeout if necessary
  };
  
  return (
    <div className={`${scrolled ? "bg-red-500" : "bg-white"} header transition-colors duration-300`}>
      <nav className="navbar navbar-expand-xl navbar-dark gulamHeaderwrapper">
        <div className="container custom-container">
          <Link to="/" className="navbar-brand Logo" onClick={closeSidebar}>
            <img src={icons.Logo} alt="Logo" />
          </Link>

          {/* Sidebar toggle button for mobile */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas"
            aria-controls="navbarOffcanvas"
          >
            <img className="navbar-toggler-icon" src={icons.Toggle} alt="Toggle" />
          </button>

          {/* Sidebar Content */}
          <div
            className="offcanvas offcanvas-end"
            id="navbarOffcanvas"
            tabIndex="-1"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title text-light" id="offcanvasNavbarLabel">
                <img src={icons.Logo} alt="Logo" />
              </h5>
              {/* Add X (close) icon in top-right corner */}
              <button
                type="button"
                className="btn-close btn-close-white text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <img src={Xicon} alt="" />
                
              </button>
            </div>

            <div className="offcanvas-body">
              <div className="navbar-nav gulab-header-wrapper flex-grow-1 pe-3">
                {/* Conditionally render dropdown for desktop only */}
                {isDesktop && (
                  <NavDropdown
                    title="About"
                    id="basic-nav-dropdown"
                    className="customMenu common_link"
                    show={isOpen}
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                  >
                    <NavDropdown.Item
                      onMouseEnter={() => handleImage("I")}
                      onClick={() => scrollToSection("I")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                   <div className="w-full ">
                  <div>
                  Introduction
                  </div> 
               </div> 
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseEnter={() => handleImage("O")}
                      onClick={() => scrollToSection("O")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Our Gurus
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseEnter={() => handleImage("L")}
                      onClick={() => scrollToSection("L")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Life at UGMK
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onMouseEnter={() => handleImage("A")}
                      onClick={() => scrollToSection("A")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Awards
                    </NavDropdown.Item>
                    <img src={image} alt="Dropdown Icon" className="mt-3" />
                  </NavDropdown>
                )}

                <Link className="nav-item nav-link common_link text-sm md:text-base lg:text-lg" to="/ugmk-prodigies" onClick={closeSidebar}>
                  UGMK Prodigies
                </Link>

                {isDesktop && (
                  <NavDropdown
                    title="Academy"
                    id="basic-nav-dropdown"
                    className="customMenu common_link"
                    show={isShow}
                    onMouseEnter={() => setIsShow(true)}
                    onMouseLeave={() => setIsShow(false)}
                  >
                    <NavDropdown.Item
                      href="/academy"
                      onMouseEnter={() => handleImage("H")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      How it Works
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.2"
                      onMouseEnter={() => handleImage("W")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Why Us
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      onMouseEnter={() => handleImage("C")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Courses
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      onMouseEnter={() => handleImage("WS")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Workshops
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="#action/3.3"
                      onMouseEnter={() => handleImage("M")}
                      className="text-sm md:text-base lg:text-lg"
                    >
                      Media/Gallery
                    </NavDropdown.Item>
                    <img src={image} alt="Dropdown Icon" className="mt-3" />
                  </NavDropdown>
                )}
                  <div className="md:hidden block w-full">
                  <Link className="nav-item nav-link common_link text-sm md:text-base lg:text-lg" to="/about-us" onClick={closeSidebar}>
                  About
                </Link>
                  </div>
                  <div className="md:hidden block w-full">
                  <Link className="nav-item nav-link common_link text-sm md:text-base lg:text-lg" to="/academy" onClick={closeSidebar}>
                  Academy
                </Link>
                  </div>

                <Link className="nav-item nav-link common_link text-sm md:text-base lg:text-lg" to="/success-stories" onClick={closeSidebar}>
                  Success Stories
                </Link>
                <Link className="nav-item nav-link common_link text-sm md:text-base lg:text-lg" to="/contact-us" onClick={closeSidebar}>
                  Contact Us
                </Link>
                <Link className="enrollNowBtn common_link text-sm md:text-base lg:text-lg" to="/contact-us" onClick={closeSidebar}>
                  ENROLL NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
