
const emailValidation = (userData) => {
    let error= {};
    let valid = true;
  
    const emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (userData.email !== undefined && !userData.email) {
      error = "Please enter email";
      valid = false;
    } else if (userData.email !== undefined && !emailregex.test(userData.email)) {
      error = "Please enter valid email";
      valid = false;
    } else if (userData.email) {
      error = '';
    }
    return { error, valid };
  }; 
  

export const contactUsFormValidation = (userData) => {
    let errors = {};
    let isValid = true;
  
    if (userData.name !== undefined && !userData.name) {
      errors.name = "Please enter name";
      isValid = false;
    }else if(
      userData.name !== undefined &&
      !userData.name.match(/^[a-zA-Z\s]{3,}$/)){
      errors.name = "Please enter a valid name with at least 3 characters.";
      isValid = false;
    } else if (userData.name) {
      errors.name = '';
    }
  
    if (userData.email !== undefined) {
      const { error, valid } = emailValidation(userData);
      if (!valid) {
        errors.email = error;
        isValid = valid;
      } else {
        errors.email = "";
      }
    }

    if (userData.phone !== undefined && !userData.phone) {
      errors.phone = "Please enter phone number";
      isValid = false;
    } else if (
      userData.phone !== undefined &&
      !userData.phone.match(/^[6789]\d{9}$/)
    ) {
      errors.phone = "Please enter a valid 10-digit phone number starting with 6, 7, 8, or 9.";
      isValid = false;
    } else if (userData.phone) {
      errors.phone = '';
    }
    
    if (userData.location !== undefined && !userData.location) {
      errors.location = "Please select location";
      isValid = false;
    } else if (userData.location) {
      errors.location = '';
    }

    if (userData.message !== undefined && !userData.message) {
      errors.message = "Please enter message";
      isValid = false;
    }else if(userData.message !== undefined && userData.message.length<10){
      errors.message = "Please enter a valid message with at least 10 characters."
      isValid = false;
    } else if (userData.message) {
      errors.message = '';
    }
  
    return { errors, isValid };
};
  
  