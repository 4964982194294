import Logo from "./img/Logo.png";
import Homebanner from "./img/homeBanner.png";
import Mobileview from "./img/mobile.png";
import Rightarrow from "./img/rightArrow.svg";
import Aboutimg from "./img/AboutImg.png";
import Footerbg from "./img/footerBg.png";
import Spotify from "./img/spotify.svg";
import Location from "./img/location.png";
import Email from "./img/email.png";
import Call from "./img/call.png";
import Socialicon from "./img/x.svg";
import Instagram from "./img/instagram.svg";
import Facebook from "./img/ELEMENTS.svg";
import ARRehman from "./img/ArRehman.png";
import Luckyali from "./img/Lucky-Ali.png";
import Step1 from "./img/step1.svg";
import Step2 from "./img/step2.svg";
import Step3 from "./img/step3.svg";
import Courseimg from "./img/CourseImg.png";
import Arrowcircle from "./img/arrowCircle.svg";
import Courseimg2 from "./img/CourseImg2.png";
import Devotional from "./img/devotional.png";
import Contactbanner from "./img/ContactBanner.png";
import About from "./img/about.png";
import Introimg from "./img/Introductionimg.png";
import Murtuja from "./img/Murtuja.png";
import Hasan from "./img/Hasan.png";
import Qadir from "./img/qadir.png";
import Award from "./img/Award.png";
import Preview from "./img/preview.svg";
import Next from "./img/next.svg";
import Aboutmenu from "./img/menuImg.png";
import Redarrow from "./img/redArrow.svg";
import Amittrivedi from "./img/amit-trivedi.png";
import Rightquote from "./img/rightQuote.svg";
import Leftquote from "./img/leftQuote.svg";
import Thumbnail from "./img/Thumbnail1.png";
import Thumbnail1 from "./img/Thumbnail2.png";
import Thumbnail2 from "./img/Thumbnail3.png";
import Thumbnail3 from "./img/Thumbnail4.png";
import TermsOfUses from "./img/termOfUses.png";
import Privacypolicy from "./img/PrivacyPolicy.png";
import Refund from "./img/refund.png";
import FAQ from "./img/Faq.png";
import Ustadji from "./img/Ustadji.png";
import Toggle from './img/hamburger.png';
import rightnewarrow from './img/rightarrow.png'
import leftnewarrow from './img/Leftarrow.png'
export const icons = {
  Ustadji,
  FAQ,
  Refund,
  Thumbnail,
  Leftquote,
  Rightquote,
  Logo,
  Homebanner,
  Rightarrow,
  Aboutimg,
  Footerbg,
  Spotify,
  Location,
  Email,
  Call,
  Socialicon,
  Instagram,
  Facebook,
  ARRehman,
  Luckyali,
  Step1,
  Step2,
  Step3,
  Courseimg,
  Arrowcircle,
  Courseimg2,
  Devotional,
  Contactbanner,
  About,
  Introimg,
  Murtuja,
  Hasan,
  Qadir,
  Award,
  Preview,
  Next,
  Aboutmenu,
  Redarrow,
  Amittrivedi,
  TermsOfUses,
  Privacypolicy,
  Thumbnail1,
  Thumbnail2,
  Thumbnail3,
  Mobileview,
  Toggle,
  rightnewarrow,
  leftnewarrow
};
