import React, { useState } from "react";
import "./contactUs.scss";
import { Container, Row, Col } from "react-bootstrap";
import { icons } from "../../Assets/imageConstants";
import { Link } from "react-router-dom";
import { contactUsFormValidation } from "../../Utils/validation";

const ContactUs = () => {
  const [details,setDetails] = useState({name:'',email:'',phone:'',location:'',message:''});
  const [errorMsg,setErrorMsg] = useState({});

  const handleChange=(e)=>{
    let {name,value} = e.target;
    let newValue = {[name]:value}
    let {errors} = contactUsFormValidation(newValue);
    setDetails({...details,[name]:value})
    setErrorMsg({...errorMsg,...errors})    
  }

  const handleSubmit=()=>{
    let {errors,isValid} = contactUsFormValidation(details)
    if(isValid){
      console.log(details)
    }else{
      setErrorMsg({...errorMsg,...errors})
    }   
  }
  console.log(details)
  console.log(errorMsg)

  return (
    <>
      <section className="ContactBanner">
        <div className="BannerImg">
          <img src={icons.Contactbanner} alt="" title="" />
          <div className="innerbannerContent">
            <Container className="custom-container">
              <div className="breadcrumb-wrapper !mt-[-85px]">
                <ul>
                  <li>
                    <Link to='/'>Home</Link>
                  </li>
                  <li>/</li>
                  <li>
                  Contact Us
                  </li>
                </ul>
              </div>
              <h2>Contact Us</h2>
            </Container>
          </div>
        </div>
      </section>
      <section className="GetinTouchContact">
        <div className="ContactTItle">
          <h3>Contact Us</h3>
          <h2 className="heading">Get In Touch</h2>
        </div>
        <Container className="custom-container">
          
          <div className="locationOuter">
            <div className="locationBox">
                <div className="IconBox flex items-center justify-center">
                  <img src={icons.Location} alt="" title="" className="!w-[10vw] md:!w-[3vw]" />
                </div>
                <h4>LOCATION</h4>
                <p>
                  205,Kalpana Apartment, New Kantwadi Road,Off Perry Cross
                  Road Bandra West, Mumbai-400050, India
                </p>
                <p>
                  <Link>Google Maps</Link>
                </p>
            </div>
            <div className="locationBox">
                  <div className="IconBox flex items-center justify-center">
                    <img src={icons.Email} alt="" title="" className="!w-[10vw] md:!w-[3vw]"/>
                  </div>
                  <h4>EMAIL</h4>
                  <p>ustadghulammustafakhan@gmail.com</p>
            </div>
            <div className="locationBox">
                  <div className="IconBox flex items-center justify-center">
                    <img src={icons.Call} alt="" title="" className="!w-[10vw] md:!w-[3vw]" />
                  </div>
                  <h4>PHONE NO.</h4>
                  <p>+91 9820410648/ +91 9833786786</p>
            </div>
            
          </div>
        </Container>
      </section>

      <section className="WriteToUs padd-btm">
        <Container className="custom-container">
          <div className="WriteInner">
            <h2>Write To Us</h2>
            <Row>
              <Col sm={6}>
                <div className="inputBox">
                  <input type="text" placeholder="Name" name="name" onChange={handleChange} />
                  <small className="error">{errorMsg.name}</small>
                </div>
              </Col>
              <Col sm={6}>
                <div className="inputBox">
                  <input type="text" placeholder="Email" name="email" onChange={handleChange} />
                  <small className="error">{errorMsg.email}</small>
                </div>
              </Col>
              <Col sm={6}>
                <div className="inputBox">
                  <input type="number" placeholder="Phone No." name="phone" onChange={handleChange} />
                  <small className="error">{errorMsg.phone}</small>
                </div>
              </Col>
              <Col sm={6}>
                <div className="inputBox">
                  <select name="location" onChange={handleChange}>
                    <option value=''>Location</option>
                    <option value='mumbai'>Mumbai</option>
                    <option value='delhi'>Delhi</option>
                  </select>
                  <small className="error">{errorMsg.location}</small>
                </div>
              </Col>
              <Col sm={12}>
                <div className="inputBox">
                  <textarea placeholder="Message" name="message" onChange={handleChange} />
                  <small className="error">{errorMsg.message}</small>
                </div>
              </Col>
            </Row>
         
           <div className="multi-btn">
           <a className="custonBtn" href="#" onClick={handleSubmit}>SUBMIT
            <span><svg xmlns="http://www.w3.org/2000/svg" width="26.198" height="18.38" viewBox="0 0 26.198 18.38">
              <path id="Path_33587" data-name="Path 33587" d="M16.129,3V27.387m0,0,8.129-8.129m-8.129,8.129L8,19.258" transform="translate(-2.25 25.319) rotate(-90)" fill="none" stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            </svg></span>
            </a>
           </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactUs
